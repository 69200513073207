<div id="contact_baner_images">
    <div style="width: 80%; margin: auto">
        <h1>{{contactData.title}}</h1>
    </div>
</div>
<!--  -->
<div id="contact_us_body" class="outer-div1">
    <div class="row">
        <div class="col-md-12 d-flex justify-content-center align-items-center">
            <h4 id="company_name">{{contactData.title1}}</h4>
        </div>
    </div>
    <div class="row" style="width: 80%; margin: auto">
        <div class="col-md-12 contact-karve">
            <div class="contact-branch-name">{{contactData.desc}}</div>
            <p class="address">
                Office No.208, Jaymala Business Court,<br />
                Nandini Takle Nagar, Manjri BK ,<br />
                Pune, Maharashtra, 412307</p>
            <p><i class="bi bi-telephone-fill"></i> {{contactData.contact}}</p>
            <p>
                <i class="bi bi-envelope-fill"></i>
                <a class="ml-2" href="mailto:enquiry@primeitskill.com">{{contactData.email}}</a>
            </p>
        </div>
    </div>
</div>
<!--  -->
<div class="contact1 flex flex-col sm:flex-row">
    <div class="contact2 container mx-auto mt-8 md:w-3/6 sm:w-3/6">
        <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" name="editForm" role="form" novalidate
            (ngSubmit)="onSubmit()" [formGroup]="editForm">
            <h2 class="md:ml-32 sm:ml-28 md:mb-4 font-bold text-2xl">Get In Touch</h2>
            <div class="flex">
                <div class="mb-4 mt-4 form-group">
                    <input
                        class="form-control w-full shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="name" formControlName="name" type="text" name="name" placeholder="Username" />
                    <div *ngIf="editForm.get('name')!.invalid && (editForm.get('name')!.dirty || editForm.get('name')!.touched)"
                        class="text-red-500 text-sm mt-1">
                        <small *ngIf="editForm.get('name')?.errors?.['required']">
                            This field is required.
                        </small>
                    </div>
                </div>

                <div class="mb-4 mt-4 ml-5 form-group">
                    <input
                        class="form-control w-full shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="contactNumber" formControlName="contactNumber" type="text" name="contactNumber"
                        placeholder="contact Number" />
                    <div *ngIf="editForm.get('contactNumber')!.invalid && (editForm.get('contactNumber')!.dirty || editForm.get('contactNumber')!.touched)"
                        class="text-red-500 text-sm mt-1">
                        <small *ngIf="editForm.get('contactNumber')?.errors?.['required']">This field is
                            required.</small>
                        <small *ngIf="editForm.get('contactNumber')?.errors?.['pattern']">Please enter a 10-digit Mobile
                            Number</small>
                    </div>
                </div>
            </div>

            <div class="mb-4 mt-2 form-group">
                <input
                    class="form-control md:w-full shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email" formControlName="email" type="text" name="email" placeholder="Email Id" />
                <div *ngIf="editForm.get('email')!.invalid && (editForm.get('email')!.dirty || editForm.get('email')!.touched)"
                    class="text-red-500 text-sm mt-1">
                    <small *ngIf="editForm.get('email')?.errors?.['required']">Email is required</small>
                    <small *ngIf="editForm.get('email')?.errors?.['email']">Email must be a valid email address.</small>
                </div>
            </div>

            <div class="mb-6 mt-6 form-group">
                <textarea
                    class="form-control md:w-full sm:w-8/12 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="requirements" formControlName="requirements" type="text" name="requirements"
                    placeholder="Requirements"></textarea>
                <div *ngIf="editForm.get('requirements')!.invalid && (editForm.get('requirements')!.dirty || editForm.get('requirements')!.touched)"
                    class="text-red-500 text-sm mt-1">
                    <small *ngIf="editForm.get('requirements')?.errors?.['required']">This field is required</small>
                </div>
            </div>

            <div *ngIf="showThankYouMessage" class="text-center text-green-500 text-md mt-1">
                Thank You for contacting us. Our team will get back to you shortly.
            </div>

            <div class="flex items-center ml-36 form-group">
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline cursor-pointer"
                    [disabled]="editForm.invalid || isSaving" type="submit" value="send">
                    Submit Form
                </button>
            </div>
        </form>
    </div>

    <div class="map sm:ml-4 mt-4">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.680039516017!2d73.95852827393502!3d18.49814676986134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c39a921fd96b%3A0x4654808bf8fcf75d!2sJaimala%20Business%20Court!5e0!3m2!1sen!2sin!4v1717681171886!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>

    

</div>
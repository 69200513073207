import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isMenuOpen = false;
  showCoursesGrid = false;
  isCoursesDropdownOpen = false;
  isHovered = false;
  showButton: boolean = true;

  constructor(private router: Router) {}

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closeMenu() {
    this.isMenuOpen = false;
    this.showCoursesGrid = false;
  }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  toggleCoursesDropdown(open: boolean): void {
    this.isCoursesDropdownOpen = open;
  }
  delayCloseCoursesDropdown(): void {}

  isActive(route: string): boolean {
    return this.router.isActive(route, true);
  }

  isCurrentPage(route: string): boolean {
    return this.router.url === `/${route}`;
  }

  navigateToCart(): void {
      this.router.navigate(['/billing']);
    }

}

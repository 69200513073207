
<div class="background">
  <div class="registerForm max-w-md mx-auto mt-10 bg-white shadow-lg rounded-lg overflow-hidden">
    <div class="headline">
      Register Now
    </div>
    <form class="py-4 px-6" role="form" novalidate (ngSubmit)="onSubmit()" [formGroup]="editForm">
      <div class="mb-4 form-group">
        <input
          class="form-control input-field appearance-none"
          id="name" formControlName="name" type="text" name="name" placeholder="Name">
        <div *ngIf="editForm.get('name')!.invalid && (editForm.get('name')!.dirty || editForm.get('name')!.touched)"
             class="text-red-500 text-sm mt-1">
          <small *ngIf="editForm.get('name')?.errors?.['required']">
            Name is required.
          </small>
        </div>
      </div>
      <div class="mb-5 form-group">
        <input
          class="form-control input-field appearance-none"
          id="email" formControlName="email" type="text" name="email" placeholder="Email Id">
        <div *ngIf="editForm.get('email')!.invalid && (editForm.get('email')!.dirty || editForm.get('email')!.touched)"
             class="text-red-500 text-sm mt-1">
          <small *ngIf="editForm.get('email')?.errors?.['required']">Email is required</small>
          <small *ngIf="editForm.get('email')?.errors?.['email']">Email must be a valid email address.</small>
        </div>

      </div>
      <div class="mb-5 form-group">
        <input
          class="form-control input-field appearance-none"
          formControlName="contactNumber" id="contactNumber" type="tel" placeholder="Phone Number">
        <div *ngIf="editForm.get('contactNumber')!.invalid && (editForm.get('contactNumber')!.dirty || editForm.get('contactNumber')!.touched)"
             class="text-red-500 text-sm mt-1">
          <small *ngIf="editForm.get('contactNumber')?.errors?.['required']">Phone number is
            required.</small>
          <small *ngIf="editForm.get('contactNumber')?.errors?.['pattern']">Please enter a 10-digit Mobile
            Number</small>
        </div>
      </div>
      <div class="mb-5 form-group">
        <input
          class="form-control input-field appearance-none"
          id="city" type="text" placeholder="City" formControlName="city">
        <div *ngIf="editForm.get('city')!.invalid && (editForm.get('city')!.dirty || editForm.get('city')!.touched)"
             class="text-red-500 text-sm mt-1">
          <small *ngIf="editForm.get('city')?.errors?.['required']">
            City is required.
          </small>
        </div>
      </div>

      <div class="mb-5 form-group">
        <select
          class="input-field form-control"
          id="course" name="course">
          <option value="Select Course">Select Course</option>
          <option value="Full Stack Java">Full Stack Java</option>
          <option value="Full Stack Python">Full Stack Python</option>
          <option value="MERN Stack">MERN Stack</option>
          <option value="MEAN Stack">MEAN Stack</option>
          <option value="Advanced Java">Advanced Java</option>
          <option value="Springboot Framework">Springboot Framework</option>
          <option value="Web Development Program">Web Development Program</option>
          <option value="Angular/React">Angular/React</option>
        </select>
      </div>
      <div *ngIf="showThankYouMessage" class="text-center text-green-500 text-md mt-1">
        Thank You for contacting us. Our team will get back to you shortly.
      </div>
      <div class="flex">
        <button class="button" id="button-2" [disabled]="editForm.invalid || isSaving" type="submit" value="send">
          <div class="slide"></div>
          <a> Submit</a>
        </button>
      </div>
    </form>
  </div>
</div>

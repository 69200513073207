<div class="nav-dropdown-container-grid grid grid-cols-1 md:grid-cols-4 gap-3 sm:flex flex-col">
    <div class="grid-item md:w-1/4" style="display: flex">
      <div *ngFor="let category of categories" class="category flex flex-col rounded-lg transition duration-500 ease-in-out transform hover:scale-105">
        <span><a routerLink="#" class="font-semibold hover:underline text-sm pl-2 underline" style="color: brown;"><i class="bi bi-check2-circle" style="font-size: 17px;"></i>{{ category.title }}</a></span>
        <ul class="pl-4 text-gray-500 text-sm">
          <li *ngFor="let course of category.courses"><a [routerLink]="course.link" class="hover:underline">{{ course.name }}</a></li>
        </ul>
      </div>
    </div>
  </div>
  
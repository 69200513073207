import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  jobOrientedCourses = [
    { name: 'Full Stack Java', routerLink: 'course-details/full-stack-java' },
    { name: 'MEAN', routerLink: 'course-details/mean-stack' },
    { name: 'MERN', routerLink: 'course-details/mern-stack' },
    { name: 'Web Expert', routerLink: 'course-details/mean-stack' },
    { name: 'Android App Development', routerLink: 'course-details/android' },
    {
      name: 'IOS App Development',
      routerLink: 'course-details/ios-app-development',
    },
    {
      name: 'Python Full Stack',
      routerLink: 'course-details/full-stack-python',
    },
  ];
  diplomaCourses = [
    {
      name: 'DMAD',
      description: 'Diploma in Mobile App Development',
      routerLink: 'course-details/DMAD',
    },
    {
      name: 'DST',
      description: 'Diploma in Software Testing',
      routerLink: 'course-details/full-stack-java',
    },
    {
      name: 'DFSWD',
      description: 'Diploma in Full Stack Development',
      routerLink: 'course-details/DFSWD',
    },
  ];
  services = [
    { name: 'Modular Training', routerLink: 'courses1' },
    { name: 'Placements', routerLink: 'placement' },
    { name: 'Privacy & Policy', routerLink: 'privacy-policy' },
    { name: 'Refund & Cancellation', routerLink: 'refunds-cancellation' },
    { name: 'Terms & Conditions', routerLink: 'terms&Condition' },
  ];

  aboutList = [
    { name: 'Explore PrimeIT', routerLink: 'about' },
    { name: 'Advantage PrimeIT', routerLink: 'Advantage' },
    { name: 'Clients', routerLink: 'Clients' },
  ];

  footerInfo: {
    desc: string;
    name: string;
    title1: string;
    contact: string;
    email: string;
    courseTitle: string;
    courseTitle1: string;
    courseTitle2: string;
    courseTitle3: string;
  } = {
    desc: 'Address',
    title1: '',
    contact: '+91 9604182720',
    email: 'sales@techcognicsindia.com',
    name: 'Address',
    courseTitle: 'Job Oriented Courses',
    courseTitle1: 'Diploma Courses',
    courseTitle2: 'Services',
    courseTitle3: 'About PrimeIT',
  };
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginFormService {
  private url = 'https://formspree.io/f/mleqnyqq'; 

  constructor(private http: HttpClient) {}

  modelQuote(data:any): Observable<any> { 
    return this.http.post<any>(this.url, data);
  }
}

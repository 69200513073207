import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-flip-card',
  templateUrl: './flip-card.component.html',
  styleUrls: ['./flip-card.component.scss'],
})
export class FlipCardComponent {
  @Input() imageSrc: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() buttonText: string = '';

  @Output() viewDetailsClicked: EventEmitter<void> = new EventEmitter<void>();

  onViewDetailsClick(): void {
    this.viewDetailsClicked.emit();
  }

  name: { btnName: string } = {
    btnName: 'View Details',
  };
}

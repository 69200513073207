import { Component, OnInit, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { CartService } from "./cart/cart.service";

@Component({
  selector: "jhi-billing",
  templateUrl: "./billing.component.html",
  styleUrls: ["./billing.component.scss"],
})
export class BillingComponent implements OnInit {
  cartService = inject(CartService);
  products: any[] = [];

  constructor(private http: HttpClient, private router: Router) {}

  activeFilter = "All";
  filteredPortfolio: any[] = [];

  filterPortfolio(category: string): void {
    if (category === "All") {
      this.filteredPortfolio = this.products;
    } else {
      this.filteredPortfolio = this.products.filter(
        (item) => item.category === category
      );
    }
    this.activeFilter = category;
  }

  addToCart(product: any) {
    this.router.navigate(["/cart", { product: JSON.stringify(product) }]);
  }

  ngOnInit(): void {
    this.http.get<any[]>("../../assets/courses.json").subscribe((data) => {
      this.products = data;
      this.filteredPortfolio = this.products;
    });
  }
}

import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoursesComponent } from './courses.component';

@NgModule({
  declarations: [CoursesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
        {
          path: 'courses1',
          component: CoursesComponent,
        },
      ]),
    ],
    schemas: [
      NO_ERRORS_SCHEMA
    ],
  })
export class CoursesModule { }

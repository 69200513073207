<div class="row">
  <div class="col-md-12 col-xs-12">
    <div class="blockquote-banner">
      <p>
        <span>
          {{courseData.description}}
        </span>
      </p>
    </div>
  </div>
</div>
<div class="image-trainer ">
  <img src="../../assets/new_first.png" alt="">
  <img src="../../assets/new_second.png" alt="">
</div>
<!--  -->
<div id="about_us_body" class="outer-div1">
  <div class="wrapper row1" style="width: 80%; margin: auto">
    <section class="hoc doit-container clear">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 d-flex align-items-center first-container">
          <div class="row">
            <h5 class="doit-title">{{aboutData.title}}</h5>
            <h2>
              {{aboutData.description}}
            </h2>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 second-container contain">
          <h3 class="heading">{{aboutData.title2}}</h3>
          <p>
            <i class="bi bi-dot"></i>{{aboutData.desc4}}<br /> - {{aboutData.desc3}} <br />- {{aboutData.desc2}}<br /> -
            {{aboutData.desc1}}
          </p>
        </div>
      </div>
    </section>
  </div>
</div>
<!-- new images -->
<div class="cont m-8 grid grid-cols-3 gap-x-5 gap-y-8">
  <img src="../../assets/img3.png" class="rounded-xl md:h-72 w-96 hover:scale-105 transition duration-150 ease-in-out"
    alt="" />
  <img src="../../assets/sixth.png"
    class="rounded-xl md:h-72 w-96 transform hover:scale-105 transition duration-150 ease-in-out" alt="" />
  <img src="../../assets/img2.png" class="rounded-xl h-72 md:w-96 hover:scale-105 transition duration-150 ease-in-out"
    alt="" />
</div>
<!--  -->
<div class="wrapper row1" style="width: 80%; margin: auto">
  <section class="hoc facts-container clear">
    <div class="row">
      <div class="col-md-6 col-sm-12 col-xs-12 facts-first-container justify-content-start">
        <h2>Bold, Hard Facts</h2>
        <ul>
          <li>
            <p><strong>FOUNDED IN: </strong> 2023</p>
          </li>
          <li>
            <p><strong>FOUNDED BY: </strong>ABC</p>
          </li>
          <li>
            <p><strong>Company Connects: </strong>20+</p>
          </li>
          <li>
            <p><strong>Placement: </strong> 80% Consistently</p>
          </li>
          <li>
            <p><strong>Locations: </strong>Regus - WTC 8th Floor | World Trade Center, Kharadi, Pune</p>
          </li>
        </ul>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 d-flex align-items-center facts-second-container">
        <div class="quote">
          <blockquote>
            <p id="facts" style="line-height: 2rem;">
              <span>we are constantly exploring new fields of knowledge as well as new training methodologies.</span>
            </p>
          </blockquote>
          <p><i> - Team PrimeIT</i></p>
        </div>
      </div>
    </div>
    <div class="clear"></div>
  </section>
</div>
<!--  -->
<div class="wrapper row2 hidden" id="founder">
  <section class="hoc class-owner clear">
    <div class="row justify-content-around owner-row">
      <div class="col-md-5 col-sm-12 col-xs-12 first-owner">
        <div class="owner-image">
          <!-- <img src="../../content/images/aboutus1.jpg"> -->
        </div>
        <div class="text">
          <h5>Vishal Jagtap</h5>
          <h3>Founder &amp; CEO</h3>
          <p> “ We are learners! At PrimeIT we are continually learning and innovating new ways
            of delivering tech efficiently to our students ”</p>
        </div>
      </div>

      <div class="col-md-5 col-sm-12 col-xs-12 first-owner">
        <div class="owner-image">
          <!-- <img src="../../content/images/aboutus2.jpg"> -->

        </div>
        <div class="text">
          <h5>Sonal Toshniwal</h5>
          <h3>Co-Founder &amp; MD</h3>
          <p>“ PrimeIT is a brand, students and recruiters trust. We are committed to customer
            success ”</p>
        </div>
      </div>
    </div>
  </section>
</div>
<!--  -->
<div class="wrapper row1" id="size-matters">
  <section class="hoc different-container clear">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12 align-items-center">
        <h5 class="different-title">{{sizeMatters.title}}</h5>
        <h2>{{sizeMatters.content}}</h2>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 different second-container">
        <p>
          {{sizeMatters.description}}
        </p>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 core-container">
        <h5>{{coreValues.title}}</h5>
        <ul>
          <li>{{coreValues.list}}</li>
          <li>{{coreValues.list1}}</li>
          <li>{{coreValues.list2}}</li>
          <li>{{coreValues.list3}}</li>
        </ul>
      </div>
    </div>
  </section>
</div>
<!--  -->
<div class="wrapper row1" id="crew">
  <section class="hoc crew-container clear">
    <div class="row">
      <div class="col-md-6 col-sm-12 col-xs-12">
        <img class="rounded-md" src="../../assets/meet.png">
      </div>
      <div class="col-md-5 col-sm-12 col-xs-12 d-flex align-items-center second-crew-container">
        <div class="row ">
          <h5 class="crew-title">{{data.title}}</h5>
          <h2>{{data.content}}</h2>
          <p>{{data.description}}
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
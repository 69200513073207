import { Component } from '@angular/core';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent {
data=[
  {img:'../../assets/clients/clients1.png'},
  {img:'../../assets/clients/clients2.png'},
  {img:'../../assets/clients/clients3.png'},
  {img:'../../assets/clients/clients4.png'},
  {img:'../../assets/clients/clients5.png'},
  {img:'../../assets/clients/clients6.png'},
  {img:'../../assets/clients/clients7.png'},
  {img:'../../assets/clients/clients8.png'},
  {img:'../../assets/clients/clients9.png'},
  {img:'../../assets/clients/clients10.png'},
  {img:'../../assets/clients/clients11.png'},
  {img:'../../assets/clients/clients12.png'},
  {img:'../../assets/clients/clients13.png'},
  {img:'../../assets/clients/clients14.png'},
  {img:'../../assets/clients/clients15.png'},
  {img:'../../assets/clients/clients16.png'},
  {img:'../../assets/clients/clients17.png'},
  {img:'../../assets/clients/clients18.png'},
  {img:'../../assets/clients/clients19.png'},
  {img:'../../assets/clients/clients20.png'},
  {img:'../../assets/clients/clients21.png'},
  {img:'../../assets/clients/clients22.png'},
  {img:'../../assets/clients/clients23.png'},
  {img:'../../assets/clients/clients24.png'},
  {img:'../../assets/clients/clients25.png'},
]
data1=[
   {
    img:'../../assets/shubham.jpg',
    title:'shubham Shivne',
    desc:'"PrimeIt is best and effective institute to develop our knowledge. Staff is very good and always present to help. Placement is very good. And they do not let you down. I feel lucky have such a helpful people."'
   },
   {
    img:'../../assets/poorva.jpeg',
    title:'poorva Pijdurkar',
    desc:'"PrimeIt Technologies is a Best Training Institute for IT Sector. I really appreciate their guidance in terms of Technology. All Faculty of PrimeIt is very helpful in nature. I am really grateful to PrimeIT Technologies. I am placed in BNT Soft Pvt Ltd. Thank you for giving me this Opportunity."'
   },
   {
    img:'../../assets/akansha.jpeg',
    title:'Akansha Hadole',
    desc:'"I Enjoy the sessions with PrimeIT. He makes the learning experience supportive and also challenging, we discuss topics of interested that hold my attention. Also helpful for technical assignments. I always had same logic how to implements in different way. Its so helpful me what can I better do in future in my development industry."'
   },
   {
    img:'../../assets/sonali.jpeg',
    title:'Sonali Bhagwat',
    desc:'"Best training and placement facility in Pune for Android, which gives you best knowledge and placement. I have completed Android App development course through PrimeIT Technologies and got placed in Max Secure"'
   },
   {
    img:'../../assets/shubham.jpg',
    title:'shubham Shivne',
    desc:'"PrimeIT Technology is best and effective institute to develop our knowledge. Staff is very good and always present to help. Placement is very good. And they do not let you down. I feel lucky have such a helpful people."'
   },
   {
    img:'../../assets/poorva.jpeg',
    title:'poorva Pijdurkar',
    desc:'"PrimeIT Technologies is a Best Training Institute for IT Sector. I really appreciate their guidance in terms of Technology. All Faculty of PrimeIT is very helpful in nature. I am really grateful to PrimeIT Technologies. I am placed in BNT Soft Pvt Ltd. Thank you for giving me this Opportunity."'
   },
]

}
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
})
export class CoursesComponent {
  courseName: { name: string } = {
    name: 'Courses',
  };

  courseCategories = [
    {
      title: 'Frontend Development',
      class: 'web-course-row',
      courses: [
        { name: 'HTML/CSS', routerLink: 'course-details/full-stack-python' },
        { name: 'JavaScript', routerLink: '' },
        { name: 'Typescript', routerLink: '' },
        { name: 'Bootstrap', routerLink: '' },
        { name: 'Angular/React', routerLink: '' },
        { name: 'Git', routerlink: ''}
      ],
    },
    {
      title: 'Backend Development',
      class: 'java-course-row',
      courses: [
        { name: 'Core JAVA', routerLink: '' },
        { name: 'Threading', routerLink: '' },
        { name: 'REST API', routerLink: '' },
        { name: 'Microservices', routerLink: '' },
        { name: 'SpringBoot', routerLink: '' },
        { name: 'Spring Data JPA', routerLink: '' },
        { name: 'Servlet', routerlink:''},
        { name: 'MySQL', routerlink:''},
        { name: 'Git', routerlink:''}
      ],
    },
    {
      title: 'FullStack Development',
      class: 'mobile-course-row',
      courses: [
        { name: 'HTML/CSS', routerLink: '' },
        { name: 'JavaScript', routerLink: '' },
        { name: 'Angular/React', routerLink: '' },
        { name: 'Core JAVA', routerLink: '' },
        { name: 'REST API', routerlink:''},
        { name: 'SpringBoot', routerlink:''},
        { name: 'Spring Data JPA', routerlink:''},
        { name: 'Microservices', routerlink:''},
        { name: 'MySQL', routerlink:''},
        { name: 'Git' ,routerlink:''}
      ],
    },
    {
      title: 'Programming Languages',
      class: 'programming-course-row',
      courses: [
        { name: 'C Programming', routerLink: '' },
        { name: 'C++ Programming', routerLink: '' },
        { name: 'Data Structure using C, C++', routerLink: '' },
        { name: 'Python', routerLink: '' },
      ],
    },
    {
      title: 'Database Technologies',
      class: 'database-course-row',
      courses: [{ name: 'MySQL', routerLink: '' }],
    },
  ];
}

import { Component } from '@angular/core';

interface AboutData {
  description: string;
  title?: string;
  title2?: string;
  desc1: string;
  desc2: string;
  desc3: string;
  desc4: string;
}

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent {
  courseData: { description: string } = {
    description:
      "At PrimeIT, we are reshaping the landscape of technology education! Pioneering a new era in IT training, we are renowned for our outcome-driven methods. Our unwavering commitment is towards delivering top-notch training quality and instilling a cutting-edge mindset in our learners. With PrimeIT, success is not an option; it's a certainty.",
  };

  aboutData: AboutData = {
    description:
      ' We are deeply passionate about technology and committed to transforming conventional methods in tech education. Our objective is to empower individuals to grasp the true nature of technology, cultivating an outstanding and profound comprehension of its intricacies.',
    title: 'WHY WE DO IT',
    title2: 'It All Started in 2023...',
    desc1:
      'Renowned as the premier IT Training and Placement Company for Freshers in Pune, we firmly believe in the transformative power of mentorship. Our mission is to shape fresh talent into seasoned Software Professionals, ensuring a robust start to their careers. Join us at PrimeIT, where every learning experience is a joyous journey!',
    desc2:
      'Having successfully trained over 200+ students and professionals, we take pride in our dedicated team of 15+ individuals working tirelessly for the success of our students and the satisfaction of our recruiters.',
    desc3:
      'Established in 2023, our primary goal was to provide unparalleled training to emerging professionals, catering to the escalating demand for skilled IT professionals nationwide.Presently, our expansive offices span across two distinct locations in Pune.',
    desc4:
      'PrimeIT stands out as a hub for top-notch tech enthusiasts. Our team is consistently immersed in the ever-evolving tech landscape, honing our skills to align with the latest trends and ensuring seamless delivery to our students.',
  };

  data: { description: string; title: string; content: string } = {
    description:
      'We are dedicated to imparting technology the right way to professionals, students, and businesses, aiding them in reaching the pinnacle of excellence.',
    title: 'PLEASED TO MEET YOU!',
    content: 'Get Acquainted with Our Team',
  };

  sizeMatters: { description: string; title: string; content: string } = {
    description:
      'PrimeIT excels in technology expertise, leading the market in Web, Testing, and Mobile app domains. Our unwavering commitment to quality is non-negotiable, with personalized attention given to every student. Enjoy top-notch post-training support, engaging with live projects and alumni discussions for a dynamic and realistic learning experience. We continuously encourage innovation, out-of-the-box thinking, and pursuing the next big breakthrough among our students and professionals.',
    title: 'SIZE MATTERS',
    content: 'Why We are Different',
  };

  coreValues: {list: string;title: string;list1: string;list2: string;list3: string} = {
    title: 'Our Core Values',
    list: 'Honoring Clients',
    list1: 'Appreciating the Value of Customers Resources',
    list2: 'Upholding Integrity',
    list3: 'Embracing a Culture of Continuous Learning, Innovation, and Collaboration',
  };
}

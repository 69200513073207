import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { HomeModule } from './home/home.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { AboutModule } from './about/about.module';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { ContactModule } from './contact/contact.module';
import { CoursesModule } from './courses/courses.module';
import { CourseDropdownComponent } from './course-dropdown/course-dropdown.component';
import { LoginFormModule } from './login-form/login-form.module';
import { PlacementModule } from './placements/placement.module';
import { AdvantageModule } from './advantages/advantage.module';
import { clientsModule } from './clients/clients.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RegisterModule } from './register/register.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CourseDropdownComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    AboutModule,
    HomeModule,
    ContactModule,
    CoursesModule,
    HttpClientModule,
    LoginFormModule,
    PlacementModule,
    AdvantageModule,
    clientsModule,
    ReactiveFormsModule,
    RegisterModule,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}

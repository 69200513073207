<div class="up-container bg-stone-600 w-full h-96 p-10" 
style="background-image: url('../../assets/clients/Clientimg1.jpg');
 background-size: cover; background-position: center;">
    <h1 class="text-white text-5xl">Clients</h1>
</div>

<h1 class="title text-4xl text-center mt-11">Our Clients</h1>
<div class="container">
    <div class="con flex flex-wrap">
        <div *ngFor="let item of data" class="con-img w-1/5">
            <img [src]='item.img' class="h-16 w-48 mt-9" alt="">
        </div>
    </div>
</div>

<div class="wrapper mt-5">
    <div class="slider">
        <h2 id="reviews" class="test text-3xl font-mono mb-10 text-center mt-11">Clients Testinomials</h2>
        <div class="slide-track">
            <div class="slide flex">
                <div *ngFor="let slide of data1"
                    class="relative w-[351px] bg-[#fefefe] rounded-md m-[15px] text-center pt-3">
                    <div class=" flex justify-start">
                        <div>
                            <img [src]="slide.img" alt="">
                        </div>
                        <div class="mt-6 ml-5">
                            <span class="bi bi-person-circle">
                                <a class="font-semibold text-sm pl-2">{{slide.title}}</a></span><br />
                            <div class=""> <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill ml-1"></i>
                                <i class="bi bi-star-fill ml-1"></i>
                                <i class="bi bi-star-fill ml-1"></i>
                                <i class="bi bi-star-fill ml-1"></i>
                            </div>
                        </div>
                    </div>
                    <p class="text-sm p-3 text-justify">{{slide.desc}}</p>
                </div>
            </div>
        </div>
    </div>
</div>

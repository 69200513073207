<section class="product">
    <div class="container mx-auto text-center">

        <div class="row justify-center shadow-md filtered" data-aos="fade-up" data-aos-delay="100">
            <ul id="portfolio-flters" class="flex space-x-4">
                <li (click)="filterPortfolio('All')" [class.filter-active]="activeFilter === 'All'">Full-Stack</li>
                <li (click)="filterPortfolio('frontend')" [class.filter-active]="activeFilter === 'frontend'">
                    FrontEnd</li>
                <li (click)="filterPortfolio('backend')" [class.filter-active]="activeFilter === 'backend'">
                    BackEnd</li>
            </ul>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div class="col-md-3 shadow-lg" *ngFor="let product of filteredPortfolio">
                <div class="box">
                    <div class="prod_img">
                        <img [src]="product.imageUrl" alt="Product Image">
                    </div>
                    <h6>{{ product.desc }}</h6>
                    <div class="subTotal flex mt-1">
                        <div class="star">{{product.rating}} <i class="bi bi-star-fill"></i>
                            <i class="bi bi-star-fill ml-1"></i>
                            <i class="bi bi-star-fill ml-1"></i>
                            <i class="bi bi-star-fill ml-1"></i>
                            <i class="bi bi-star-half ml-1"></i>
                        </div>
                        <h4><i class="bi bi-currency-rupee"></i> {{ product.price }}</h4>
                    </div>
                    <div class="totalHrs mt-1">{{product.totalHrs}} . {{product.lectures}}</div>
                    <button type="button" class="cart_btn" (click)="addToCart(product)">Add To Cart</button>
                </div>
            </div>
        </div>
    </div>
</section>
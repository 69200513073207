import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./about/about.module').then((m) => m.AboutModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./courses/courses.module').then((m) => m.CoursesModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./login-form/login-form.module').then((m) => m.LoginFormModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./courses-details/coursesDetails.module').then(
        (m) => m.CourseModule
      ),
  },
  {
    path: '',
    loadChildren: () => import('./placements/placement.module').then((m) => m.PlacementModule),
  },
  {
    path: '',
    loadChildren: () => import('./advantages/advantage.module').then((m) => m.AdvantageModule),
  },
  {
    path: '',
    loadChildren: () => import('./clients/clients.module').then((m) => m.clientsModule),
  },
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

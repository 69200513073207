import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginFormService } from './login-form.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  showFooter = false;
  isSaving = false;
  showloginMessage: boolean = false;
  editForm = new FormGroup({
    name: new FormControl(null, { validators: [Validators.required, Validators.maxLength(50)] }),
    email: new FormControl(null, { validators: [Validators.required, Validators.email] }),
  });
  constructor(public router: Router , private loginFormService:LoginFormService) {}

  onSubmit(){
    this.isSaving = true;
    const formDetails = this.editForm.getRawValue();
    this.loginFormService.modelQuote(formDetails).subscribe({
      next: () => this.onSaveSuccess(),
      error: () => this.onSaveError(),
    });
  }
  
  private onSaveSuccess(): void {
    this.editForm.reset();
    this.showloginMessage = true;
    setTimeout(() => {
      this.showloginMessage = false;
      this.router.navigateByUrl('/');
    }, 5000);
  }
  
  private onSaveError(): void {
    this.isSaving = false;
  }
}
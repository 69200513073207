<div class="pb-[100px]">
  <div class="up-container  w-full h-96 p-10">
    <h1 class="text-white text-5xl">Placements</h1>
  </div>

  <div class="low-container pl-[200px] pt-[40px] pr-[250px]">
    <h1 class="text-red-700 text-xl ">Are you a fresher looking for job? PrimeIt will help you get one!</h1>

    <p class="text-slate-700 pt-5 text-justify">We transform a fresher into an IT professional. We do understand what
      the IT companies are looking for, we know exactly what skills are needed in the IT industry. We enhance the skill
      set of a fresher by providing them appropriate tech skills and make them job ready. Be it mobile apps, be it web,
      software testing, cloud, data science or anything we have the best skilled trainers for each of them. With a team
      of highly motivated and enthusiastic professionals we have achieved the best placement numbers for freshers in
      pune.</p>

    <h1 class="text-red-700 text-xl mt-8 ">Facts</h1>
    <div class="mt-4">
      <p class="text-slate-600"><i class="bi bi-dot"></i>90% Placement Track Record</p>
      <p class="text-slate-600"><i class="bi bi-dot"></i>465+ Recruiters</p>
      <p class="text-slate-600"><i class="bi bi-dot"></i>2.4 lacs Avg. Annual Package Offered</p>
      <p class="text-slate-600"><i class="bi bi-dot"></i>Unlimited Calls</p>
    </div>

    <h1 class="text-red-700 text-xl mt-14 ">Our Commitment</h1>
    <div class="mt-3">
      <p class="text-slate-600 text-justify"><i class="bi bi-dot"></i>Complete transparency in Placement processes.</p>
      <p class="text-slate-600 text-justify"><i class="bi bi-dot"></i>We do not guarantee 100% placement, however our
        placement numbers speak about us. We have more than 90% placement consistent track record.</p>
      <p class="text-slate-600 text-justify"><i class="bi bi-dot"></i>We have 100% genuine placements. We are serious,
        and stick to our ETHICS.</p>
      <p class="text-slate-600 text-justify"><i class="bi bi-dot"></i>We provide placement till the time you are not
        placed.</p>
      <p class="text-slate-600 text-justify"><i class="bi bi-dot"></i>We value your money, so we do not charge anything
        extra, for placements.</p>
    </div>

    <h1 class="text-red-700 text-xl mt-14">What makes PrimeIt special?</h1>
    <h2 class="text-slate-600 mt-8"><b>Knowledge is the Key to Success.</b></h2>
    <p class="text-slate-600 mt-4 text-justify">At PrimeIt, the Quality of Training is highest priority. We make sure
      that our students get in-depth & real time <br>knowledge of the technology along with detailed implementation of
      what has been learnt.<br> This is the most important thing, why a PrimeIt is always preferred over others.</p>

    <h2 class="text-slate-600 mt-8"><b>Live Projects</b></h2>
    <p class="text-slate-600 mt-4 text-justify">We make sure that every student at PrimeIt gets a chance to work on live
      project.<br> Working on live project enhances the technology understanding, and makes you aware of the runtime
      scenarios,<br> best processes and puts weight on Resume.</p>

    <h2 class="text-slate-600 mt-8"><b>Going that Extra Mile</b></h2>
    <p class="text-slate-600 mt-4"><i class="bi bi-dot"></i>We arrange expert sessions on latest trends in IT industry,
      by professionals.</p>
    <p class="text-slate-600 "><i class="bi bi-dot"></i>Encourage students to innovate new ideas and implement it too.
    </p>
    <p class="text-slate-600"><i class="bi bi-dot"></i>Identify weak areas in the early stage and focus on improving
      those.</p>


    <h2 class="text-slate-600 mt-8"><b>Soft skills and Life Skills sessions by IQBIT</b></h2>
    <p class="text-slate-600 mt-4 text-justify">IQBIT is a pioneering curriculum development organization, offering
      programs that explicitly grow skills for learning, skills for employment and skills for life. IQBIT’s programs are
      designed and developed for students transitioning to professionals and for professionals who want to transform
      into an asset for their employer. All the programs are focused around the development of personal, social and
      employability skills.</p>

    <h1 class="text-4xl mt-14">Why PrimeIt?</h1>
    <h2 class="text-red-700 text-xl mt-8">Why Recruiters like to recruit from PrimeIt ?</h2>
    <p class="text-slate-600 mt-4 text-justify"><i class="bi bi-dot"></i>Get Job ready candidate with professional
      knowledge.</p>
    <p class="text-slate-600 text-justify"><i class="bi bi-dot"></i>Get technical support from PrimeIt for their
      candidates.</p>
    <p class="text-slate-600 text-justify"><i class="bi bi-dot"></i>Quick responsive placement team, always ready to
      help out recruiters to scrutinize candidate and speed up the process.</p>
  </div>

</div>


import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-course-dropdown',
  templateUrl: './course-dropdown.component.html',
  styleUrls: ['./course-dropdown.component.scss']
})
export class CourseDropdownComponent {
  @Output() closeDropdown: EventEmitter<void> = new EventEmitter<void>();

  categories = [
    {
      title: 'Frontend Development',
      courses: [
        { name: 'HTML', link: 'course-details/mean-stack' },
        { name: 'CSS', link: 'course-details/mean-stack' },
        { name: 'JavaScript', link: 'course-details/mean-stack' },
        { name: 'Bootstrap', link: 'course-details/mern-stack' },
        { name: 'TypeScript', link: 'course-details/full-stack-java' },
        { name: 'Angular/React', link: ''},
        { name: 'Git', link:''}
      ]
    },
    {
      title: 'Backend Development',
      courses: [
        { name: 'Core JAVA', link: 'course-details/full-stack-java' },
        { name: 'J2EE', link: 'course-details/full-stack-java' },
        { name: 'Threading', link: 'course-details/full-stack-java' },
        { name: 'REST API', link: 'course-details/full-stack-java' },
        { name: 'Microservices', link: 'course-details/full-stack-java' },
        { name: 'SpringBoot', link: 'course-details/full-stack-python' },
        { name: 'Spring Data JPA',link:''},
        { name: 'Servlet', link:''},
        { name: 'MySQL', link:''},

      ]
    },
    {
      title: 'FullStack Development',
      courses: [
        { name: 'HTML & CSS', link: 'course-details/android' },
        { name: 'JavaScript', link: 'course-details/ios-app-development' },
        { name: 'Angular/React', link: 'course-details/android' },
        { name: 'J2EE', link: 'course-details/ios-app-development' },
        { name: 'REST API', link:''},
        { name: 'SpringBoot', link:''},
        { name: 'Spring Data JPA', link:''},
        { name: 'Microservices', link:''},
        { name: 'MySQL', link:''},
     
      ]
    },
    {
      title: 'Digital Marketing',
      courses: [
        { name: 'C Programming', link: '' },
        { name: 'C++ Programming', link: '' }
      ]
    }
  ];
}

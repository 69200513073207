<div class="flex justify-center ">
    <div class="group w-64 h-48 rotate-container mx-2">
        <div class="rotate-card transition-all duration-1000 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
            <div class="side front" style="background-color: whitesmoke;align-items:center;">
                <div
                    class="course-card-title h-full w-full rounded-xl object-cover shadow-md shadow-black/15 flex-col align-center pt-10">
                    <span class="flex items-center ml-10">
                        <img [src]="imageSrc" class="mr-2.5">
                        <div>
                            <h1 class="font-bold text-md text-black">{{ title }}</h1>
                        </div>
                    </span>
                    <div class="flex align-center ml-12">
                        <i class="bi bi-calendar-week" style="margin-right: 5px;"></i>
                        <p class="text-sm">{{ description }}</p>
                    </div>
                    <div class="flex align-center ml-12">
                        <i class="bi bi-clock" style="margin-right: 5px;"></i>
                        <p class="text-sm">{{ buttonText }}</p>
                    </div>
                </div>
            </div>
            <div class="side back rounded-xl">
                <div class="absolute inset-0 h-full w-full rounded-xl px-6 text-center text-slate-200">
                    <div class="flex h-full flex-col items-center justify-center">
                        <img [src]="imageSrc" class="align-center h-auto max-w-full rounded-lg">
                        <button class="mt-2 rounded-md py-1 px-2 text-lg text-black hover:text-red-400" 
                        (click)="onViewDetailsClick()">{{name.btnName}}
                      </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component } from '@angular/core';

@Component({
  selector: 'app-advantages',
  templateUrl: './advantages.component.html',
  styleUrls: ['./advantages.component.scss']
})
export class AdvantagesComponent {
 data= [{
    img1:'../../assets/Advantages/adv1.png',
     title1:'Best QUALITY Training',
     content1:'PrimeIt is known for its Training quality. We have the best team to make sure that we deliver technology the way it should be. We are completely Quality Focused customer centric organisation.',
     title2:'Faculties with Corporate Experience',
     content2:'All of our faculties are experts in their respective domains. The average experience of faculty at PrimeIt is 8 years.',
    img2:'../../assets/Advantages/adv5.png',
    title3:'Online Classrooms (Theory and Practical)',
    content3:'This helps students implement and understand the topic in depth and create a string base for the next topic.',
    title4:'Extra Assignments',
    content4:'Loads of assignments to make sure that the students have done the implementation in depth. The assignments ratings and reviews are tracked online, which helps us to identify the problems and difficulties students and facing and facilitates us to provide right solutions.'
  
    },
    
    {
      img1:'../../assets/Advantages/adv3.png',
      title1:'Programming Tests Every Week',
      content1:'Every saturday there would be an Online Programming Test and a machine test, to make sure students are confident enough to implement complex problem definitions within time limits.',
      title2:'Soft Skills & Life Skills Session',
      content2:'4 Days of Soft Skills and Life Skills session for students helping students to focus, build confidence, learn effective communication and turn themselves into a complete professional. These sessions are conducted by Trainer/Mentor having 22+ years of experience in various domains of IT industry.',
      img2:'../../assets/Advantages/adv4.png',
      title3:'Live Projects',
      content3:'primeIt Students get a chance to work on Live Projects. This exposes students and builds deep understanding about challenges in real time projects. Students who work on Live Projects have minimum 40% more chances of placement than those who do not work on Live Projects.',
      title4:'Mock Interviews and Alumni Sessions',
      content4:'There are minimum 2 Mock Interviews and Alumni sessions for students. Mock Interviews help students to understand their drawback and work on them prior to actual placements. Alumni sessions gives a chance to students to talk to our Alums who are experts in the respective domain. This helps to understand the latest tech trends and recruiters expectations from candidates.'
    },
    {
      img1:'../../assets/Advantages/adv5.png',
      title1:'Technical Presentations',
      content1:'Students are allocated a topic every week, which they have to prepare and present as part of the batch presentations. This helps students to learn new things about technology, gain confidence and improve presentation skills.',
      title2:'Reattain Complete Batch',
      content2:'Students are allowed to attain same course batch for next one year.',
      img2:'../../assets/Advantages/adv6.png',
      title3:'Placement',
      content3:'We have got a consistent record of average 90% placement throughout all the courses. We have got 460+ recruiters on board and the number is growing consistently. We are transparent to our recruiters as much as we are to our students. We provide unlimited call to our students without charging anything extra for placement.',
      title4:'Apps and Portals',
      content4:'We have student app where students can see their attendance, assignments and placement calls. We have got internal portals for each technology where students can find presentations, articles and can appear for tests to make the learning concrete.'
    }
]

}

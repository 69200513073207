<div class="privacy mb-[80px]">
    <div class="image-container w-full h-96 pt-40">
        <h1 class="test text-white text-4xl justify-start ml-[63px] ">
            <b>
                PRIVACY POLICY - PRIME IT ACADEMY
            </b>
        </h1>
    </div>
    <div class="container text-start ml-14 mt-11 mr-14 containText">
        <div class="info">
            <p>
                <b>PrimeIT Academy</b> understands that your privacy is
                important to you and that you care about how your personal data is
                used. We respect and value the privacy of everyone who visits this
                website, https://PrimeITindia.com/ (“Our Site”) and as described
                in Parts 5 and 6, below, we do not collect personal data about you
                unless you contact us. Any personal data we do collect will only be
                used as permitted by law.
            </p>
            <p>
                Please read this Privacy Policy carefully and ensure that you
                understand it. Your acceptance of this Privacy Policy is deemed to
                occur upon your first use of Our Site. If you do not accept and
                agree with this Privacy Policy, you must stop using Our Site
                immediately.
            </p>
        </div>

        <div class="first mt-5">
            <h2><b>1. Information About Us:</b></h2>
            <p>
                Our Site is owned and operated by PrimeIT Academy.<br /><b>Telephone Number:</b> 9890452720<br />
                <b>Registered Address:</b> Regus - Pune, WTC Unit No 801 8th Floor,
                World Trade Center Office No 848, EON Free Zone, Kharadi, Pune<br />
                <b> Email Address: </b>sales@PrimeITindia.com<br />
                <b> Postal Address:</b> Unit No 801, 8th Floor, WORLD TRADE CENTER,
                Dholepatil Farms Rd, EON Free Zone, Kharadi, Pune, Maharashtra
                411014<br />
            </p>
        </div>

        <div class="second mt-[30px]">
            <h2><b>2. What Does This Policy Cover?</b></h2>
            <p>
                This Privacy Policy applies only to your use of Our Site. Our Site may contain links to other
                websites. Please note that we have no control over how your data is
                collected, stored, or used by other websites and we advise you to
                check the privacy policies of any such websites before providing any
                data to them.
            </p>
        </div>

        <div class="third mt-[30px]">
            <h2><b>3. What is Personal Data?</b></h2>
            <p>
                Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the
                “GDPR”) as ‘any information relating to an identifiable person who
                can be directly or indirectly identified by reference to an
                identifier’. Personal data is, in simpler terms, any information
                about you that enables you to be identified. Personal data covers
                obvious information such as your name and contact details, but it
                also covers less obvious information such as identification numbers,
                electronic location data, and other online identifiers.
            </p>
        </div>

        <div class="four mt-[30px]">
            <h2><b>4. What Are My Rights?</b></h2>
            <p>
                Under the GDPR, you have the following
                rights, which we will always work to uphold:
            </p>
            <p class="mt-[2px]">
                <b>a)</b>The right to be informed about our collection and use of
                your personal data. This Privacy Policy should tell you everything
                you need to know, but you can always contact us to find out more or
                to ask any questions using the details in Part 10.
            </p>
            <p class="mt-[2px]">
                <b>b)</b> The right to access the personal data we hold about you.
                Part 9 will tell you how to do this.
            </p>
            <p class="mt-[2px]">
                <b>c)</b> The right to have your personal data rectified if any of
                your personal data held by us is inaccurate or incomplete. Please
                contact us using the details in Part 10 to find out more
            </p>
            <p class="mt-[2px]">
                <b>d)</b> The right to be forgotten, i.e. the right to ask us to
                delete or otherwise dispose of any of your personal data that we
                have. Please contact us using the details in Part 10 to find out
                more.
            </p>
            <p class="mt-[2px]">
                <b>e)</b> The right to restrict (i.e. prevent) the processing of
                your personal data.
            </p>
            <p class="mt-[2px]">
                <b>f) </b>The right to object to us using your personal data for a
                purpose or purposes.
            </p>
            <p class="mt-[2px]">
                <b>g)</b> The right to data portability. This means that, if you
                have provided personal data to us directly, we are using it with
                your consent or for the performance of a contract, and that data is
                processed using automated means, you can ask us for a copy of that
                personal data to re-use with another service or business in many
                cases.
            </p>
            <p class="mt-[2px]">
                <b>h)</b> Rights relating to automated decision-making and
                profiling. We do not use your personal data in this way. For more
                information about our use of your personal data or exercising your
                rights as outlined above, please contact us using the details
                provided in Part 10. Further information about your rights can also
                be obtained from the Information Commissioner’s Office or your local
                Citizens Advice Bureau. If you have any cause for complaint about
                the use of your personal data, you have the right to lodge a
                complaint with the Information Commissioner’s Office.
            </p>
        </div>

        <div class="fifth mt-[30px]">
            <h2><b>5. What Personal Data Do You Collect?</b></h2>
            <p>
                Subject to the following, we do not collect any personal data from you. We do not
                place cookies on your computer or device, nor do we use any other
                means of data collection.If you send us an email, we may collect
                your name, your email address, and any other information which you
                choose to give us.
            </p>
        </div>

        <div class="sixth mt-[30px]">
            <h2><b>6. How Do You Use My Personal Data?</b></h2>
            <p>
                If we do collect any personal data, it will be processed and stored securely, for no
                longer than is necessary considering the reason(s) for which it was
                first collected. We will comply with our obligations and safeguard
                your rights under the GDPR always. For more details on security see
                Part 7, below. As stated above, we do not generally collect any
                personal data. If you contact us and we obtain your personal details
                from your email, we may use them to respond to your email. All
                emails containing your personal data will be deleted after the
                subject matter of your email has been resolved. You have the right
                to withdraw your consent to us using your personal data at any time,
                and to request that we delete it. We will not share any of your data
                with any third parties for any purposes other than storage on an
                email server.
            </p>
        </div>

        <div class="seven mt-[30px]">
            <h2><b>7. How and Where Do You Store My Data?</b></h2>
            <p>
                We will only store your personal data in the UK. This means that it will be fully
                protected under the GDPR.
            </p>
        </div>

        <div class="eight mt-[30px]">
            <h2><b>8. Do You Share My Personal Data?</b></h2>
            <p>
                We will not share any of your personal data with any third parties for any purposes, subject
                to one important exception.
            </p>
            <p class="mt-[2px]">
                In some limited circumstances, we may be legally required to share
                certain personal data, which might include yours, if we are involved
                in legal proceedings or complying with legal obligations, a court
                order, or the instructions of government authority.
            </p>
        </div>

        <div class="nine mt-[30px]">
            <h2><b>9. How Can I Access My Personal Data?</b></h2>
            <p>
                If you want to know what personal data we have about you, you can ask us for details of
                that personal data and for a copy of it (where any such personal
                data is held). This is known as a “subject access request”.
            </p>
            <p class="mt-[2px]">
                All subject access requests should be made in writing and sent to
                the email or postal addresses shown in Part 10. There is no charge
                for a subject access request.
            </p>
            <p class="mt-[2px]">
                We will respond to your subject access request within one month of
                receiving it. Normally, we aim to provide a complete response,
                including a copy of your personal data within that time. In some
                cases, however, particularly if your request is more complex, more
                time may be required up to a maximum of three months from the date
                we receive your request. You will be kept fully informed of our
                progress.
            </p>
        </div>

        <div class="ten mt-[30px]">
            <h2><b>10. How Do I Contact You?</b></h2>
            <p>
                To contact us about anything to do
                with your personal data and data protection, including to make a
                subject access request, please use the following details for the
                attention of Office of HR at PrimeIT Academy,<br><b>Telephone
            Number : </b> 9890452720 <br> <b>Registered Address :</b> Regus - Pune, WTC Unit No
                801 8th Floor, World Trade Center Office No 848, EON Free Zone,
                Kharadi, Pune. <br><b>Email Address : </b>sales@PrimeITindia.com<br><b>Postal Address : </b>Unit No 801, 8th Floor,
                WORLD TRADE CENTER, Dholepatil Farms Rd, EON Free Zone, Kharadi,
                Pune, Maharashtra 411014 .
            </p>
        </div>

        <div class="eleven mt-[30px]">
            <h2><b>11. Changes to this Privacy Policy</b></h2>
            <p>
                We may change this Privacy Notice from time to time. This may be
                necessary, for example, if the law changes, or if we change our
                business in a way that affects personal data protection.
            </p>
            <p class="mt-[2px]">
                Any changes will be immediately posted on Our Site and you will be
                deemed to have accepted the terms of the Privacy Policy on your
                first use of Our Site. We recommend that you check this page
                regularly to keep up-to-date
            </p>
        </div>
    </div>
</div>
<div class="carousel-inner">
  <div class="carousel-item active">
    <div id="android_training"></div>
    <div class="overlay-item">
      <div class="moto">
        <h2>{{homeInfo.title}}</h2>
        <h3>{{homeInfo.desc}}</h3>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div class="flex flex-col justify-center">
  <h2 class="text-3xl text-center font-semibold mt-9">{{homeInfo.courseTitle}}</h2>

  <div class="flipcard flex flex-wrap justify-center">
    <div *ngFor="let card of flipCards; let i = index" class="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 group mb-8">
      <jhi-flip-card [imageSrc]="card.imageSrc" [title]="card.title" [description]="card.description"
      [buttonText]="getDynamicDate()" (viewDetailsClicked)="onViewDetailsClicked(card.name)"
        class="mb-4"></jhi-flip-card>
    </div>
  </div>
</div>
<!--  -->
<div class="flex justify-center items-center intro">
  <div class="flex flex-row items-center dir">
    <div class="w-1/2 flex justify-center">
      <img class="rounded-lg" src="../../assets/secure.png" alt="image">
    </div>
    
    <div class="w-1/2 flex flex-col justify-center introtext">
      <div class="text-center mb-4"  id="getfirstjob-title">
        {{ homeInfo.courseTitle1 }}
      </div>
      <div class="text-center mx-4"  id="getfirstjob-desc">
        {{ homeInfo.content }}
      </div>
    </div>
  </div>
</div>

<!--  -->
<div class="quotes">
  <h1 class="q1">
    <q>
      Enroll in our career-focused training program now and <br />learn from the premier IT training company in Pune!
    </q>
  </h1>
</div>
<!--  -->
<div class="high">
  <div class="high-high">
    <h2 class="text-4xl text-center font-mono mb-12 -mt-9">{{homeInfo.courseTitle2}}</h2>
  </div>
  <div class="high-courses">
    <div *ngFor="let item of data" class="high-box">
      <img [src]="item.imgsrc" alt="">
      <h3 class="h-up" id="java">{{item.title}}</h3>
      <h5>{{item.desc}}</h5>
      <p class="h-para">{{item.content}}
      </p>
    </div>
  </div>
</div>
<!--  -->
<section id="counts" class="counts">
  <div class="container" data-aos="fade-up">
    <div class="row" style="display:flex;">
      <div class="col-md-3 col-sm-6 col-xs-12">
        <div class="count-box">
          <p>No. of Students Trained</p>
          <span data-purecounter-start="0" data-purecounter-end="20" data-purecounter-duration="2"
            class="purecounter">{{ count1 + "+" }}</span>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-xs-12">
        <div class="count-box">
          <p>Customised Courses</p>
          <span data-purecounter-start="0" data-purecounter-end="100" data-purecounter-duration="2"
            class="purecounter">{{ count2 + "+"}}</span>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-xs-12">
        <div class="count-box">
          <p>Placement</p>
          <span data-purecounter-start="0" data-purecounter-end="250+" data-purecounter-duration="4"
            class="purecounter">{{ count3 + "%"}}</span>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-xs-12">
        <div class="count-box">
          <p>Company Connects</p>
          <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="2"
            class="purecounter">{{ count4 + "+" }}</span>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-xs-12">
        <div class="count-box">
          <p>Live Projects</p>
          <span data-purecounter-start="0" data-purecounter-end="250+" data-purecounter-duration="4"
            class="purecounter">{{ count5 + "+"}}</span>
        </div>
      </div>
    </div>
  </div>
</section>
<!--  -->
<div class="wrapper row2 diploma-courses-bitcode" id="advantage-main">
  <h2 class="text-4xl text-center font-mono mb-12 -mt-9">{{ homeInfo.courseTitle3 }}</h2>
  <div class="row" id="advantage-title">
    <div class="col-md-4 px-2" id="adv-title"> 
      <div class="courseTitle flex font-mono justify-start items-center text-2xl text-white">
        {{ homeInfo.courseTitle3Desc }}
      </div>
    </div>

    <div class="col-md-4 px-2 adv1" id="adv-main"> 
      <div class="row no-gutters">
        <div class="col-12 dcourses">
          <div *ngFor="let advantage of advantages" class="d-flex justify-content-center align-items-center mb-2" id="adv-text">
            {{ advantage }}
          </div>
        </div>
      </div>
    
    <div class="col-md-4 px-2 adv" id="adv-main"> 
      <div class="row no-gutters">
        <div class="col-12">
          <div *ngFor="let advantages1 of advantages" class="d-flex justify-content-center align-items-center mb-2" id="adv-text">
            {{ advantages1 }}
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
<!--  -->
<div class="wrapper row2 diploma-cources-bitcode" id="success-code" style="width: 80%;">
  <div class="row d-flex" id="success">
    <div class="col-md-7 d-flex">
      <div class="col-md-12 d-flex justify-content-start align-items-center">
        <span class="text-4xl font-medium font-mono">{{homeInfo.courseTitle4}}</span>
      </div>
    </div>
    <div class="col-md-5 d-flex justify-content-center align-items-center">
      <div class="col-md-12" id="bitcode-success">
        <p class="text-md font-sans leading-6">
          {{homeInfo.courseTitle4Desc}}
        </p>
        <span style="color: rgb(98, 147, 212);">{{homeInfo.hashtags}}</span>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div class="card-container -mt-14 sm:mt-9 mx-24">
  <div *ngFor="let testimonial of testimonials;" class="card">
    <div class="card-up">
      <img class="rounded-full" [src]="testimonial.imageSrc" alt="" height="40px" width="40px">
      <div class="card-side">
        <h2>{{testimonial.title}}</h2>
        <h5>{{testimonial.company}}</h5>
      </div>
    </div>
    <div class="card-low">
      <p>{{testimonial.description}}</p>
    </div>
  </div>
</div>
<!--  -->
<div class="wrapper row6">
  <section class="container py-5 my-lg-3 my-xl-4 my-xxl-5">
    <div class="py-2 py-sm-3 py-md-4 my-md-2 my-xxl-3">
      <h2 class="text-4xl text-center font-mono pt-xxl-1" style="text-transform: none">
        {{homeInfo.courseTitle5}}
      </h2>
      <p class="text-lg text-center pb-3 pt-3 px-20 mb-lg-4 text-stone-500 mt-4">
        {{homeInfo.courseTitle5Desc}}
      </p>
    </div>
  </section>
</div>
<!--  -->
<div class="images flex m-8 grid gap-x-6 gap-y-8 grid-cols-3">
  <img src="../../assets/image1.png"
    class="rounded-xl md:h-60 w-96 hover:scale-105 transition duration-150 ease-in-out" alt="" />
  <img src="../../assets/img3.png"
    class="rounded-xl md:h-60 w-96 transform hover:scale-105 transition duration-150 ease-in-out" alt="" />
  <img src="../../assets/image3.png" class="rounded-xl h-64 md:w-96 hover:scale-105 transition duration-150 ease-in-out"
    alt="" />
</div>
<div class="icons-container">
  <a href="https://api.whatsapp.com/send?phone=9890452720"
    class="rounded-full text-white bg-green-700 hover:bg-green-800"><i class="bi bi-whatsapp ml-3"></i></a>
</div>

<div class="main">
  <header class="header" id="header">
    <div class="info-bar">
      <div class="container">
        <div class="header-content">
          <div class="wrapper row0">
          <span class=""><i class="bi bi-telephone-x-fill"></i><a href="tel:+91 9890452720" id="google_playstore">+91 9890452720</a></span>
            <div class="social-icons">
              <a href=""><i class="bi bi-facebook"></i></a>
              <a href="mailto:sales@techcognicsindia.com"><i class="bi bi-google"></i></a>
              <a href=""><i class="bi bi-twitter"></i></a>
              <a href=""><i class="bi bi-linkedin"></i></a>
              <a href=""><i class="bi bi-instagram"></i></a>
              <button type="button" (click)="navigateToCart()"><i class="bi bi-cart-check-fill" style="font-size: 22px;"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="navigation" id="navigation">
    <div class="container d-flex align-items-center justify-content-lg-between">
      <a href="index.html" class="logo me-lg-0">
        <img src="../../assets/9.png" alt="Logo" class="img-fluid" />
      </a>
      <nav id="navbar" class="navbar order-last order-lg-0">
        <ul class="desktop-menu d-flex justify-content-center" *ngIf="!isMenuOpen" (click)="scrollToTop()">
          <li><a class="nav-link scrollto" routerLink="" [class.visited]="isCurrentPage('')"
              [class.active]="isActive('')">Home</a></li>
          <li>
            <a class="nav-link scrollto" routerLink="courses1" (click)="toggleCoursesDropdown(!isCoursesDropdownOpen)"
              (mouseenter)="toggleCoursesDropdown(true)" (mouseleave)="delayCloseCoursesDropdown()">
              Courses
            </a>
          </li>
          <li><a class="nav-link scrollto" routerLink="placement" [class.visited]="isCurrentPage('placement')"
            [class.active]="isActive('placement')">Placements</a></li>
          <li><a class="nav-link scrollto" routerLink="about" [class.visited]="isCurrentPage('about')"
              [class.active]="isActive('about')">About Us</a></li>
          <li><a class="nav-link scrollto" routerLink="contact" [class.visited]="isCurrentPage('contact')"
              [class.active]="isActive('contact')">Contact</a></li>

        </ul>

        <nav class="navbar order-last order-lg-0 navbar-mobile" *ngIf="isMenuOpen">
          <ul>
            <i class="bi mobile-nav-toggle" [ngClass]="{ 'bi-x': isMenuOpen, 'bi-list': !isMenuOpen }"
              (click)="toggleMenu()"></i>
            <li><a class="nav-link scrollto active" (click)="closeMenu()" routerLink="">Home</a></li>
            <li><a class="nav-link scrollto" (click)="closeMenu()" routerLink="courses1">Courses</a></li>
            <li><a class="nav-link scrollto" (click)="closeMenu()" routerLink="placement">placement</a></li>
            <li><a class="nav-link scrollto" (click)="closeMenu()" routerLink="about">About Us</a></li>
            <li><a class="nav-link scrollto" (click)="closeMenu()" routerLink="contact">Contact</a></li>

          </ul>
        </nav>
        <i class="bi mobile-nav-toggle" [ngClass]="{ 'bi-list': !isMenuOpen }" (click)="toggleMenu()"></i>
      </nav>
      <a routerLink="login" class="get-started-btn scrollto ">Student Login</a>
    </div>
  </div>
</div>

<div class="courses-dropdown-container" *ngIf="isCoursesDropdownOpen" (mouseenter)="toggleCoursesDropdown(true)"
  (mouseleave)="toggleCoursesDropdown(false)">
  <app-course-dropdown></app-course-dropdown>
</div>


import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlipCardComponent } from '../flip-card/flip-card.component';
import { HomeComponent } from './home.component';

@NgModule({
  declarations: [HomeComponent,FlipCardComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
        {
          path: '',
          component: HomeComponent,
        },
      ]),
    ],
    schemas: [
      NO_ERRORS_SCHEMA
    ],
  })
export class HomeModule { }

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DatePipe],  
})
export class HomeComponent {
  count1: number = 0;
  count2: number = 0;
  count3: number = 0;
  count4: number = 0;
  count5: number = 0;

  coursetype: string = '';

  constructor(private router: Router,private datePipe: DatePipe) {}

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onViewDetailsClicked(coursetype: string) {
    this.router.navigate(['/course-details', coursetype]);
    this.scrollToTop();
  }

  getDynamicDate(): string {
    const today = new Date();
    let nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    const targetDate = today.getDate() < 15 ? today : nextMonth;
  
    targetDate.setDate(15);
    const formattedDate = this.datePipe.transform(targetDate, 'dd MMMM yyyy');
  
    return formattedDate || '';
  }

  testimonials = [
    {
      title: 'Shubham Shivne',
      company: 'TechCognics India',
      description:'Hey there! My name is Shubham. PrimeITSkill played a pivotal role in shaping my career as a Java Full Stack Developer. With their comprehensive curriculum covering Java, Spring Boot, and front-end technologies, I successfully secured a associate role at TechcognicsIndia Pvt Ltd.',
      imageSrc: '../../assets/shubham.jpg',
    },
    {
      title: 'Poorva Pijdurkar',
      company: 'TechCognics India',
      description:'Hello! I am Poorva. Transitioning into a skilled Java Full Stack Developer was made possible by PrimeITSkill. Their structured training provided me with the expertise needed to secure an trainee position at TechcognicsIndia Pvt Ltd., where I continue to thrive.',
      imageSrc: '../../assets/poorva.jpeg',
    },
    {
      title: 'Akansha Hadole',
      company: 'TechCognics India',
      description:'Hello! I am Akansha Hadole. PrimeITSkill Academy empowered me to achieve success as a Java Full Stack Developer. Through their immersive training, I landed a junior developer role at DEF Innovations, where I contribute to impactful projects.',
      imageSrc: '../../assets/akansha.jpeg',
    },
    {
      title: 'Sonali Bhagwat',
      company: 'TechCognics India',
      description:'Hey! I am Sonali. PrimeITSkill Academy prepared me for success as a Java Full Stack Developer. Securing a trainee position at GHI Solutions was a testament to the valuable skills and knowledge I acquired through their training.',
      imageSrc: '../../assets/sonali.jpeg',
    },
  ];

  flipCards = [
    {
      imageSrc: '../../assets/java.png',
      title: 'Full Stack Java',
      name: 'full-stack-java',
      description: '200 Hrs (3.5 months)',
      buttonText: '15 March 2024',
    },
    {
      imageSrc: '../../assets/python.png',
      title: 'Full Stack Python',
      name: 'full-stack-python',
      description: '200 Hrs (3.5 months)',
      buttonText: '15 March 2024',
    },
    {
      imageSrc: '../../assets/angular.png',
      title: 'MEAN Stack',
      name: 'mean-stack',
      description: '200 Hrs (3.5 months)',
      buttonText: '15 March 2024',
    },
    {
      imageSrc: '../../assets/react.png',
      title: 'MERN Stack',
      name: 'mern-stack',
      description: '200 Hrs (3.5 months)',
      buttonText: '15 March 2024',
    },
    {
      imageSrc: '../../assets/new/android.png',
      title: 'Android',
      name: 'android',
      description: '200 Hrs (3.5 months)',
      buttonText: '15 March 2024',
    },
    {
      imageSrc: '../../assets/new/apple.png',
      title: 'Ios App Development',
      name: 'ios-app-development',
      description: '200 Hrs (3.5 months)',
      buttonText: '15 March 2024',
    },
    {
      imageSrc: '../../assets/new/dmad1.png',
      title: 'DMAD',
      name: 'DMAD',
      description: '200 Hrs (3.5 months)',
      buttonText: '15 March 2024',
    },
    {
      imageSrc: '../../assets/new/dfswd.png',
      title: 'DFSWD',
      name: 'DFSWD',
      description: '200 Hrs (3.5 months)',
      buttonText: '15 March 2024',
    },
  ];

  accuratecountstop: any = setInterval(() => {
    this.count1++;
    if (this.count1 == 200) {
      clearInterval(this.accuratecountstop);
    }
  }, 25);

  revenuecountstop: any = setInterval(() => {
    this.count2++;
    if (this.count2 == 15) {
      clearInterval(this.revenuecountstop);
    }
  }, 50);

  counterstop: any = setInterval(() => {
    this.count3++;
    if (this.count3 == 80) {
      clearInterval(this.counterstop);
    }
  }, 60);

  countstop: any = setInterval(() => {
    this.count4++;
    if (this.count4 == 20) {
      clearInterval(this.countstop);
    }
  }, 80);

  countstop5: any = setInterval(() => {
    this.count5++;
    if (this.count5 == 10) {
      clearInterval(this.countstop5);
    }
  }, 90);


  courses = [
    {
      name: 'Full Stack Java',
      icon: 'java.png',
      duration: '200 Hrs (3.5 months)',
      date: '15 March 2024',
      link: '',
    },
    {
      name: 'Full Stack Java',
      icon: 'java.png',
      duration: '200 Hrs (3.5 months)',
      date: '15 March 2024',
      link: '',
    },
    {
      name: 'Full Stack Java',
      icon: 'java.png',
      duration: '200 Hrs (3.5 months)',
      date: '15 March 2024',
      link: '',
    },
    {
      name: 'Full Stack Java',
      icon: 'java.png',
      duration: '200 Hrs (3.5 months)',
      date: '15 March 2024',
      link: '',
    },
    {
      name: 'Full Stack Java',
      icon: 'java.png',
      duration: '200 Hrs (3.5 months)',
      date: '15 March 2024',
      link: '',
    },
    {
      name: 'Full Stack Java',
      icon: 'java.png',
      duration: '200 Hrs (3.5 months)',
      date: '15 March 2024',
      link: '',
    },
  ];

  data = [
    {
      title:'Front End Development',
      desc:'Get your Career on Fast Track!',
      content:'Our front-end development team creates visually attractive and extremely interactive user interfaces. We create digital landscapes that engage, inspire, and leave a lasting impression at PrimeIt.',
      imgsrc:'/assets/java-full-stack.png'
    },
    {
      title:'Back End Development',
      desc:'100 Job Guarantee Training Program',
      content:'The solid foundation of our applications is our expertise in back-end development. Technologies like Springboot and Java are used to create an engine that is scalable, secure, and performs at peak efficiency.',
      imgsrc:'/assets/Dmad.png'
    },
    {
      title:'Full Stack Development',
      desc:'Build Successful Career in Full Stack!',
      content:'At TechCognics India, our we ensure dependable, scalable, and feature-rich solutions by fusing front-end artistry with back-end expertise. At TechCognics India we are committed to transforming concepts into meaningful realities.',
      imgsrc:'/assets/new/full-stack-python-training-and-placement-for-freshers.png'
    }
  ]

  homeInfo: {desc:string; name: string; title: string; content: string;email:string,courseTitle:string;courseTitle1:string;courseTitle2:string;courseTitle3:string;courseTitle3Desc:string;courseTitle4:string;courseTitle4Desc:string;hashtags:string;courseTitle5:string;courseTitle5Desc:string} = {
    desc:'Learn from the Best',
    title: 'To succeed, its crucial to make the right choices! Choose PrimeIT!',
    content: 'Do not wait for your first job, create it. PrimeITs job-oriented courses transform you into an IT professional, ensuring not just a job but a rewarding career. Our commitment to your success is unwavering. Securing the first job is challenging, but if you are passionate about technology and ready to work hard, let us mentor you into the incredible realm of success.',
    email:'sales@techcognicsindia.com',
    name: 'Address',
    courseTitle:'JOB ORIENTED COURSES',
    courseTitle1:'#SecureYourFirstJob',
    courseTitle2:'Highlighted Courses',
    courseTitle3:'Benefits of Choosing PrimeIT',
    courseTitle3Desc:'"At PrimeIT, renowned for setting industry standards, we provide top-notch training of the highest quality. Our inventive training approaches empower students to establish a robust technical foundation, paving the way for a successful career!"',
    courseTitle4:'PrimeITs Victorious Journeys!',
    courseTitle4Desc:'  PrimeIT has empowered individuals to forge impressive careers and transform their lives! Our PrimeIT superheroes, whose stories serve as inspirations to aspiring IT professionals, are a testament to our impact. Explore a selection of PrimeIT case studies for a dose of positivity and inspiration.',
    hashtags:'#PrimeIT #SuccessStories #Inspiration',
    courseTitle5:'Experience at PrimeIT',
    courseTitle5Desc:' Continually breaking boundaries, we persistently venture into uncharted realms of knowledge and innovative training approaches to enhance our customer experience. Our fervor lies in delivering technology in a precise manner to professionals, students, and corporations, aiding them in attaining excellence.'
  };

  advantages = [
    'Exceptional Training Quality',
    'Instructors with Corporate Background',
    'Pioneer and Premier Training Institute',
    'Live Projects guided by Industry Leaders',
    'Regular Evaluations',
    'Exclusive Portals and Applications'
  ];

  advantages1 = [
    "Mock Interviews, Skill Enhancement Workshops",
    "Collaboration with 10+ Live Projects",
    "100% Placement!",
    "Endless Placement Opportunities",
    "Robust Post-Training Assistance",
    "Full Transparency in Operations!"
  ];

}


import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClientsComponent} from './clients.component';

@NgModule({
  declarations: [ClientsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
        {
          path: 'Clients',
          component: ClientsComponent,
        },
      ]),
    ],
    schemas: [
      NO_ERRORS_SCHEMA
    ],
  })
export class clientsModule { }
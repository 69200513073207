import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PlacementsComponent } from './placements.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { BillingComponent } from '../billing/billing.component';
import { CartComponent } from '../billing/cart/cart.component';
import { CancellationComponent } from '../cancellation/cancellation.component';
import { TermsConditionComponent } from '../privacy-policy/terms-condition/terms-condition.component';

@NgModule({
  declarations: [
    PlacementsComponent,
    PrivacyPolicyComponent,
    BillingComponent,
    CartComponent,
    CancellationComponent,
    TermsConditionComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'placement',
        component: PlacementsComponent,
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
      },
      {
        path: 'billing',
        component: BillingComponent,
      },
      {
        path: 'cart',
        component: CartComponent,
      },
      {
        path: 'refunds-cancellation',
        component: CancellationComponent,
      },
      {
        path: 'terms&Condition',
        component: TermsConditionComponent,
      },
    ]),
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class PlacementModule {}

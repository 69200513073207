<div>
  <div class="flex w-full">
    <div class="left bg-black w-[120px] h-96"></div>

    <div class="mid bg-stone-600 w-full h-96 pt-10">
      <h1 class="text-white text-4xl ml-[150px]">Advantage PrimeIt</h1>
      <p class="upp text-white ml-[150px] mt-[30px]">
        We are the Best IT Training and Placement Company in Pune! PrimeIt is
        known for it's quality trainig and completely transparent process which
        ensures that the students get what is commited to them.
      </p>
      <p class="lowp text-white ml-[150px] mt-[30px]">
        Quality Training | Practical Oriented | Post Training Support | Best
        Placements
      </p>
    </div>
    <div class="right bg-black w-[120px] h-96"></div>
  </div>

  <div *ngFor="let item of data" class="placements">
    <div class="first flex justify-between ml-[70px] mt-[100px] mr-[90px] mb-[100px]">
      <div class="first-img">
        <img class="rounded-md" [src]="item.img1" alt="" />
      </div>
      <div class="content ml-[150px]">
        <h3 class="title text-2xl">{{ item.title1 }}</h3>
        <p class="desc mt-[20px]">{{ item.content1 }}</p>
        <h3 class="title sh text-2xl mt-[30px]">{{ item.title2 }}</h3>
        <p class="desc mt-[20px]">{{ item.content2 }}</p>
      </div>
    </div>
    <div>
      <div class="second flex justify-between mt-[150px] mr-[90px] mb-[100px]">
        <div class="content1 ml-[90px] mr-[100px]">
          <h3 class="title1 text-2xl">{{ item.title3 }}</h3>
          <p class="desc1 mt-[20px]">{{ item.content3 }}</p>
          <h3 class="title1 sh text-2xl mt-[30px]">{{ item.title4 }}</h3>
          <p class="desc1 mt-[20px]">{{ item.content4 }}</p>
        </div>
        <div class="second-img">
          <img class="rounded-md" [src]="item.img2" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="header-offset content-wrapper about-wrapper">
    <div class="terms-container">
        <div class="row">
            <div class="col-sm-8 col-sm-offset-2">
                <section class="terms-title">
                    <h1>Refund & Cancellation Policy</h1>
                    <hr>
                </section>

                <div class="terms-body">
                    <h4><strong>Welcome to The Main Label. Please review the following basic terms that govern your use
                            of, and purchase of, courses from our site. Please note that your use of our site
                            constitutes your agreement to follow and be bound by those terms.</strong></h4>
                    <hr>
                    <h3>General</h3>
                    <p>
                        We have a 30-day return policy for our courses, which means you have 30 days after purchase to
                        request a return.<br>
                        To start a return, you can contact us at <a
                            href="mailto:sales@techcognicsindia.com">sales@techcognicsindia.com</a>
                        You can always contact us for any return questions at <a
                            href="mailto:sales@techcognicsindia.com">sales@techcognicsindia.com</a>
                        Your satisfaction is our priority, and we're here to help with any concerns you may have
                        regarding your course purchase.<br>
                    </p>
                    <hr>

                    <h3>Refunds</h3>
                    <p>
                        We will notify you once we’ve received and inspected your return to let you know if the refund
                        was approved or not. If approved, you’ll be automatically refunded on your original payment
                        method within 10 business days. Please remember it can take some time for your bank or credit
                        card company to process and post the refund too.<br>
                        If more than 15 business days have passed since we’ve approved your return, please contact us at
                        <a href="mailto:sales@techcognicsindia.com">sales@techcognicsindia.com</a><br>
                    </p>
                    <hr>
                    <h3>Cancellations</h3>
                    <p>
                        If you decide that you no longer want your order for any reason you may cancel it as long as the
                        campaign period is still active.
                    </p>
                    <hr>
                    <h3>Courses Availability</h3>
                    <p>Although availability may be indicated on our site, we cannot guarantee product availability or
                        immediate delivery. We reserve the right, without liability or prior notice to revise,
                        discontinue, or cease to make available any or all products or to cancel any order.</p>
                    <hr>
                    <h3>Shipping & Delivery</h3>
                    <p>
                        If you still have not received your purchase after the above mentioned times, please notify <a
                            href="mailto:sales@techcognicsindia.com">sales@techcognicsindia.com</a><br>
                    <div class="container terms_footer">
                        <h3>Can't find what you're looking for? <a href="mailto:sales@techcognicsindia.com">Email us</a>
                        </h3>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<section class="cart-section">
    <h2>Cart Items</h2>
    <table>
        <thead>
            <tr>
                <th>Course</th>
                <th>Course Description</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Sub Total</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of cartItems; let i = index">
                <td><img [src]="item.imageUrl" alt="Course Image" width="100"></td>
                <td>{{ item.desc }}</td>
                <td>{{ item.price }}</td>
                <td>
                    <button class="btn" (click)="increaseQuantity(item)"><i class="bi bi-plus"></i></button>
                    {{ item.quantity }}
                    <button class="btn" (click)="decreaseQuantity(item)"><i class="bi bi-dash"></i></button>
                </td>
                <td>{{ calculateTotalPrice(item) }}
                    <button class="btn" (click)="removeFromCart(i)"><i class="bi bi-trash"></i></button>
                </td>
            </tr>
        </tbody>
    </table>
    <h3>Total: {{ calculateCartTotal() }}</h3>
</section>
<div id="courses_baner_images" style="width: 100%; margin: auto;">
    <div style="width: 80%; margin: auto">
        <h1>{{courseName.name}}</h1>
    </div>
</div>
<!--  -->
<div id="course_list">
    <div class="row">
      <div class="col-md-4" *ngFor="let courseCategory of courseCategories">
        <div [class]="courseCategory.class">
          <h3><span class="bi bi-check2-circle" aria-hidden="true"></span>{{courseCategory.title}}</h3>
          <div *ngFor="let course of courseCategory.courses" style="line-height: 7px;">
            <div><a [routerLink]="course.routerLink">{{course.name}}</a></div>
            <div><hr /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  

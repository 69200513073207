import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegisterService } from './register.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  isSaving = false;
  showloginMessage: boolean = false;
  showThankYouMessage: boolean = false;
  editForm = new FormGroup({
    name: new FormControl(null, { validators: [Validators.required] }),
    email: new FormControl(null, {
      validators: [Validators.required, Validators.email],
    }),
    contactNumber: new FormControl(null, {
      validators: [
        Validators.required,
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ],
    }),
    city: new FormControl(null, { validators: [Validators.required] }),
    course: new FormControl(null),

  });
  constructor(
    public router: Router,
    private registerService: RegisterService
  ) {}

  onSubmit() {
    debugger;
    this.isSaving = true;
    const formDetails = this.editForm.getRawValue();
    debugger;
    this.registerService.registration(formDetails).subscribe({
      next: () => this.onSaveSuccess(),
      error: () => this.onSaveError(),
    });
  }

  private onSaveSuccess(): void {
    this.editForm.reset();
    this.showThankYouMessage = true;
    setTimeout(() => {
      this.showThankYouMessage = false;
    }, 5000);
  }

  private onSaveError(): void {
    this.isSaving = false;
  }

  ngOnInit(): void {}
}

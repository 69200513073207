<div class="footer">
    <div class="upper">
        <div class="first-col">
            <div class="footer-head "><b>{{footerInfo.name}}</b></div>
            <!-- <p><strong>{{footerInfo.title1}}</strong></p> -->
            <div>
                <div class="branch-name1"><b></b></div>
                <p>
                    Office No.208, Jaymala Business Court,<br />
                    Nandini Takle Nagar, Manjri BK ,<br />
                    Pune, Maharashtra, 412307</p>
                <p><i class="bi bi-envelope-fill"></i><a
                        href="mailto:enquiry@primeitskill.com"><b>enquiry@primeitskill.com</b></a>
                </p>
                <div><i class="bi bi-telephone-fill"></i><a href="tel:+91 9604182720"><b>{{footerInfo.contact}}</b></a>
                </div>
            </div>
        </div>
        <div class="second-col">
            <div class="footer-head"><b>{{footerInfo.courseTitle}}</b></div>
            <ul class="mt-2">
                <li *ngFor="let course of jobOrientedCourses"><a [routerLink]="course.routerLink" (click)="scrollToTop()">{{ course.name }}</a>
                </li>
            </ul>
        </div>
        <div class="third-col">
            <div class="footer-head"><b>{{footerInfo.courseTitle1}}</b></div>
            <ul class="mt-2">
                <li *ngFor="let course of diplomaCourses">
                    <a [routerLink]="course.routerLink" (click)="scrollToTop()">{{ course.name }}<br />({{ course.description }})</a>
                </li>
            </ul>
        </div>
        <div class="fourth-col">
            <div class="footer-head"><b>{{footerInfo.courseTitle2}}</b></div>
            <ul >
                <li *ngFor="let service of services"><a [routerLink]="service.routerLink" (click)="scrollToTop()">{{ service.name }}</a></li>
            </ul>
        </div>
        <div class="fifth-col">
            <div class="footer-head"><b>{{footerInfo.courseTitle3}}</b></div>
            <ul class="mt-9" (click)="scrollToTop()">
                <li *ngFor="let about of aboutList"><a [routerLink]="about.routerLink">{{ about.name }}</a></li>
            </ul>
        </div>
    </div>
    <div class="lower">
        <div class="icon">
            <a href=""><i
                    class="bi bi-facebook"></i></a>
            <a href=""><i class="bi bi-linkedin"></i></a>
            <a href="mailto:enquiry@primeitskill.com"><i class="bi bi-google"></i></a>
            <a href=""><i
                    class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
        </div>
    </div>
    <a (click)="scrollToTop()" class="back-to-top flex items-center justify-center active scrollto"
        style="cursor: pointer;">
        <i class="bi bi-arrow-up-short"></i>
    </a>
</div>
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "jhi-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit {
  cartItems: any[] = [];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      this.cartItems = JSON.parse(storedCartItems);
    }

    this.route.paramMap.subscribe((params) => {
      const product = params.get("product");
      if (product) {
        const cartProduct = JSON.parse(product);
        cartProduct.quantity = 1;
        this.addToCart(cartProduct);
      }
    });
  }

  addToCart(product: any) {
    console.log("Adding product to cart:", product);
    const existingItem = this.cartItems.find((item) => item.id === product.id);
    if (existingItem) {
      existingItem.quantity++;
      console.log("Item already in cart, increasing quantity:", existingItem);
    } else {
      product.quantity = 1;
      this.cartItems.push(product);
      console.log("Item added to cart:", product);
    }
    this.updateLocalStorage();
  }

  removeFromCart(index: number) {
    this.cartItems.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(this.cartItems));
  }

  increaseQuantity(item: any) {
    item.quantity++;
    this.updateLocalStorage();
  }

  decreaseQuantity(item: any) {
    if (item.quantity > 1) {
      item.quantity--;
      this.updateLocalStorage();
    }
  }

  updateLocalStorage() {
    localStorage.setItem("cartItems", JSON.stringify(this.cartItems));
  }

  calculateTotalPrice(item: any) {
    return item.price * item.quantity;
  }

  calculateCartTotal() {
    return this.cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  }
}

<div class="min-h-screen relative">
    <div class="absolute inset-0 bg-cover"
        style="background-image: url('../../assets/login.webp'); filter: blur(1px);">
    </div>
    <div class="flex items-center justify-center">
        <div id="login_form" class="bg-white p-6 rounded-lg shadow-md w-96 relative z-10 mt-14 mb-4">
            <div class="mb-2 flex flex-col justify-center items-center">
                <img src="../../assets/admin.gif" alt="login" class="rounded-full w-20 h-20">
                <h5 class="pt-2 pb-5">Login</h5>
            </div>
            <form class="bg-white rounded px-8 pt-3 pb-4 mb-4" name="editForm" role="form" novalidate
                (ngSubmit)="onSubmit()" [formGroup]="editForm">
                <div class="mb-4 form-group">
                    <input
                        class="form-control w-full shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="name" formControlName="name" type="text" name="name" placeholder="Username*" />
                    <div *ngIf="editForm.get('name')!.invalid && (editForm.get('name')!.dirty || editForm.get('name')!.touched)"
                        class="text-red-500 text-sm mt-1">
                        <small *ngIf="editForm.get('name')?.errors?.['required']">
                            This field is required.
                        </small>
                    </div>
                </div>

                <div class="mb-6">
                    <input
                        class="form-control w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                        id="email" formControlName="email" type="text" name="email" placeholder="Email Id*" />
                    <div *ngIf="editForm.get('email')!.invalid && (editForm.get('email')!.dirty || editForm.get('email')!.touched)"
                        class="text-red-500 text-sm mt-1">
                        <small *ngIf="editForm.get('email')?.errors?.['required']">Email is required</small>
                        <small *ngIf="editForm.get('email')?.errors?.['email']">Email must be a valid email
                            address.</small>
                    </div>
                </div>

                <!-- <div class="mb-4 text-right">
                    <a href="#" class="text-blue-500 hover:underline">Forgot Password?</a>
                </div> -->

                <div *ngIf="showloginMessage" class="text-center text-green-500 text-md mt-1">
                    You are successfully logged in.
                </div>

                <div class="flex items-center justify-between form-group">
                    <button
                        class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline cursor-pointer"
                        [disabled]="editForm.invalid || isSaving" type="submit" value="send">
                        Login
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>